import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    redirect: '/home/homePage',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/home/homePage',
        name: 'homePage',
        icon:'uncheck-home',
        meta: { title: "首页" , role: [1000001, 1000002]},
        component: () => import('../views/Home/homePage/index.vue'),
      },
      {
        path: '/home/aim',
        name: 'aim',
        icon:'uncheck-aim',
        meta: { title: "目标管理", role: [] },
        component: () => import('../views/Home/aim/index.vue'),
      },
      {
        path:'/home/client',
        name:'client',
        icon:'uncheck-client',
        meta:{title:'客户管理', role: []},
        component:()=>import('../views/Home/client/index.vue')
      },
      {
        path: '/home/servant',
        name: 'servant',
        icon:'uncheck-servant',
        meta: { title: "员工管理", role: []},
        component: () => import('../views/Home/servant/index.vue'),
      },
      // {
      //   path: '/home/serbusiness',
      //   name: 'serbusiness',
      //   icon:'uncheck-server-sj',
      //   meta: { title: "商机中心", role: [] },
      //   component: () => import('../views/Home/serbusiness/index.vue'),
      //   children: [
      //     {
      //       path: '/home/serbusiness/center',
      //       name: 'center',
      //       meta: { title: "商机列表" },
      //       component: () => import('../views/Home/serbusiness/center/index.vue'),
      //     },
      //     {
      //       path: '/home/serbusiness/consumption',
      //       name: 'consumption',
      //       meta: { title: "充值消费" },
      //       component: () => import('../views/Home/serbusiness/consumption/index.vue'),
      //     },
      //   ]
      // },
      {
        path: '/home/order',
        name: 'order',
        icon:'uncheck-order',
        meta: { title: "订单管理", role: [] },
        component: () => import('../views/Home/order/index.vue'),
        children: [
          {
            path: '/home/order/orderlist',
            name: 'orderlist',
            meta: { title: "订单列表" },
            component: () => import('../views/Home/order/orderlist/index.vue'),
          },
          {
            path: '/home/order/refundlist',
            name: 'refundlist',
            meta: { title: "退款列表" },
            component: () => import('../views/Home/order/refundlist/index.vue'),
          },
          {
            
            path: '/home/order/appeallist',
            name: 'appeallist',
            meta: { title: "申诉列表" },
            component: () => import('../views/Home/order/appeallist/index.vue'),
          }
        ]
      },
      {
        path: '/home/settlement',
        name: 'settlement',
        icon:'uncheck-js',
        meta: { title: "结算管理" , role: []},
        component: () => import('../views/Home/settlement/index.vue'),
      },
      {
        path: '/home/goods',
        name: 'goods',
        icon:"uncheck-comm",
        meta: { title: "佣金管理", role: [] },
        component: () => import('../views/Home/goods/index.vue'),
      },
      {
        path: '/home/study',
        name: 'study',
        icon:'uncheck-studys',
        meta: { title: "知识中心", role: [] },
        component: () => import('../views/Home/study/index.vue'),
      },
      // {
      //   path: '/home/config',
      //   name: 'config',
      //   icon:'uncheck-config',
      //   meta: { title: "权限配置", role: [] },
      //   component: () => import('../views/Home/config/index.vue'),
      // },
// =======================================================================
      {
        
        path: '/home/client/clientDetails/:userId',
        name: 'clientDetails(二级页）',
        meta: { title: "客户管理详情"},
         hidden:true,
         component: () => import('../views/Home/client/clientDetails')
      },
      {
        path: '/home/servant/details/:userId',
        name: 'details(二级页）',
        meta: { title: "员工管理详情"},
         hidden:true,
         component: () => import('../views/Home/servant/details')
      },
      {
        path: '/home/order/orderDetails',
        name: 'orderDetails(二级页）',
        meta: { title: "订单详情页"},
         hidden:true,
         component: () => import('../views/Home/order/orderDetails')
      },
      {
        path: '/home/order/refundDetails',
        name: 'refundDetails(二级页）',
        meta: { title: "退款详情页"},
         hidden:true,
         component: () => import('../views/Home/order/refundDetails')
      },
      {
        path: '/home/order/appealDetails',
        name: 'appealDetails(二级页）',
        meta: { title: "申诉详情页"},
         hidden:true,
         component: () => import('../views/Home/order/appealDetails')
      },
      {
        path: '/home/homePage/material',
        name: 'material(二级页）',
        meta: { title: "资料设置页"},
         hidden:true,
         component: () => import('../views/Home/homePage/material')
      },
      {
        path: '/home/homePage/aimCompletionStat',
        name: 'aimCompletionStat(二级页）',
        meta: { title: "目标完成统计页"},
         hidden:true,
         component: () => import('../views/Home/homePage/aimCompletionStat')
      },
      {
        path: '/home/study/studyDetail/:id',
        name: 'studyDetail(二级页）',
        meta: { title: "学习中心详情"},
         hidden:true,
         component: () => import('../views/Home/study/studyDetail')
      },
      // studyDetail
    ],
     
  },
  {
    path: '/login',
    name: 'login',
    meta: { title: "登录页" },
    component: () => import('../views/Login.vue')
  }, 
]

const originalPush = VueRouter.prototype.push
// 修改 原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err=>err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

 //根据路由meta中权限字段 添加动态路由
 function getRouter(rol) {
  console.log(rol);
  let tempRouter = routes;
  let Temp = tempRouter[1].children.filter(item => !item.name.includes("二级页"))
  // let roleTemp  =Temp.filter(item =>item.meta.role.includes(rol)) 
  let roleTemps = Temp.filter(item => {
    for (let i = 0; i < item.meta.role.length; i++) {
      if (rol.indexOf(item.meta.role[i]) > -1) {
        return item
      }
    }
  })
  // console.log(roleTemps);  
  // return
  routes[1].children = roleTemps;
  routes.forEach((value, index) => { 
    router.addRoute(value);
  });
}

let isF = sessionStorage.getItem("isMaster"); 
// 路由前置守卫
router.beforeEach((to, from, next) => {
  let _login = sessionStorage.getItem("loginInfo");
  if (to.path == "/login") { //要跳往登录页
    next()
  } else { //否则去非登录页
    if (_login) {
      if (isF == 'false') {
        //获取登录角色
        let _role = sessionStorage.getItem("roleIds");
        getRouter(_role);
        next({
          path: to,
          replace: true
        }); 
      } else { 
        next();
      }
      // next()
    } else {
      next('/login')
    }
  }
})
export default router

/*
 * @Author: websocket 相关
 * @Date: 2022-11-05
 * @LastEditors: wumingchao wumingchao@quansantai.com
 * @LastEditTime: 2022-12-15
 * @FilePath: /zhiniu-kefu/src/assets/js/socket.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import CryptoJS from "crypto-js"; 


//aes加密
function AesEncode(word, keys) {
  let key = CryptoJS.enc.Utf8.parse(keys); // 密钥
  let iv = CryptoJS.enc.Utf8.parse(keys); // 密钥偏移量
  let srcs = CryptoJS.enc.Utf8.parse(word);
  // 对称加密算法主要有AES、DES、3DES / 非对称加密算法主要有RSA、DSA、RCC
  // iv(初始变量)
  // key(加密密钥)
  // mode(加密模式 主要有CBC(默认)、CFB、CTR、OFB、ECB)
  // padding(填充方式 主要有Pkcs7(默认)、Iso97971、AnsiX923、Iso10126、ZeroPadding)
  let encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let encryptedBase64Data = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);
  return encryptedBase64Data;
}

//aes解密
function AesDecode(word, keys) {
  let key = CryptoJS.enc.Utf8.parse(keys); // 密钥
  let iv = CryptoJS.enc.Utf8.parse(keys); // 密钥偏移量
  // 对称加密算法主要有AES、DES、3DES / 非对称加密算法主要有RSA、DSA、RCC
  // iv(初始变量)
  // key(加密密钥)
  // mode(加密模式 主要有CBC(默认)、CFB、CTR、OFB、ECB)
  // padding(填充方式 主要有Pkcs7(默认)、Iso97971、AnsiX923、Iso10126、ZeroPadding)

  let encryptedHexStr = CryptoJS.enc.Base64.parse(word);
  let srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
  let decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
  let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
  return decryptedStr.toString();
}
export default class websocket {
  constructor(url) {
    //userId
    this.userId = JSON.parse(sessionStorage.getItem("loginInfo")).userId;
    //aes key
    this.aeskey = "";
    //seq
    this.seq = 1;
    //重连判段 true重连 false不重连
    this.isReconnect = true;
    // socket url
    this.socketUrl = url;
    //websocket 实例
    this.websocket = null;
    //心跳实例
    this.heartTime = null;
    // 心跳次数
    this.socketHeart = 0;
    //心跳超时时间
    this.HeartTimeOut = 20000;
    //错误次数
    this.socketError = 0;

    //回调
    this.globalCallback = null;
    //数据结构
    this.senData = {};
  }

  //初始化
  initWebSocket(callback) {
    let that = this;
    this.websocket && this.websocket.close();
    this.websocket = new WebSocket(this.socketUrl);
    this.globalCallback = callback;
    // lockReconnect加锁，防止onclose、onerror两次重连
    localStorage.removeItem("lockReconnect");
    this.websocket.onmessage = function (e) {
      that.websocketOnMessage(e);
    };
    this.websocket.onclose = function (e) {
      that.websocketOnClose(e);
    };
    this.websocket.onerror = function () {
      that.websocketOnError();
    };
    this.websocket.onopen = function () {
      that.websocketOnOpen();
    };
    //单独调用心跳 不在初始化中使用
    // that.sendSocketHeart();
  }
  //websocket方法 连接
  websocketOnOpen() {
    this.globalCallback("连接成功");
    //连接成功 不重置心跳 //接收到数据后重置
    this.sendHeartData("login", { sessionKey:sessionStorage.getItem("NewTic") });
    //发送第一个心跳
    this.sendHeartData("heartbeat");
    this.resetHeart();
  }
  //websocket方法 关闭
  websocketOnClose(e) {
    console.log("closed=====");

    this.globalCallback("连接已关闭");

    //重连判段
    if (this.isReconnect) {
      this.reconnect();
    }
  }
  //websocket方法 错误
  websocketOnError(e) {
    console.log("error=====");
    console.log(e);
    this.reconnect();
  }
  //websocket方法 接收数据=======
  websocketOnMessage(e) {
    let _data = JSON.parse(e.data);
    if (_data.sub == "heartbeat") {
      this.resetHeart();
    }
    // 判段 返回值 readyState 状态  重置心跳
    if (e.currentTarget.readyState == 1) {
      console.log('ws登录');
      console.log(_data.code);
      //     //登录异常跳转到登录页
      if (_data.sub == "login" && (_data.code == 400002 || _data.code == 400006)) {
        this.stopSocket();
      }
      // 异常关闭，返回异常
      if (_data.sub == "logout" && _data.code != 0) {
        this.stopSocket() 
      }

      //登录应答 获取aeskey
      if (_data.sub == "login" && _data.code == 0) {
        this.aeskey = JSON.parse(_data.body).aesKey;
        //心跳返回
      } else if (_data.sub == "heartbeat") {
        this.globalCallback(_data);
      } else {
        let _decodeData = _data;
        _decodeData.body = AesDecode(_data.body, this.aeskey);
        this.globalCallback(_data);
      }
    }
  }

  //发送数据
  sendMessage(data) {
    this.websocket.send(data);
  }

  //重置心跳
  resetHeart() {
    this.socketHeart = 0;
    this.socketError = 0;
    this.heartTime && clearInterval(this.heartTime);
    this.sendSocketHeart();
  }
  //发送心跳
  sendSocketHeart() {
    this.heartTime = setInterval(() => {
      if (this.socketHeart <= 2) {
        this.sendHeartData("heartbeat", "");
        this.socketHeart = this.socketHeart + 1;
      } else {
        this.reconnect();
      }
    }, this.HeartTimeOut);
  }
  //重新连接
  reconnect(callback) {
    if (this.socketError <= 2) {
      this.isReconnect = true;
      // lockReconnect加锁，防止onclose、onerror两次重连
      if (localStorage.getItem("lockReconnect") != true) {
        localStorage.setItem("lockReconnect", 1);
        this.heartTime && clearInterval(this.heartTime);
        setTimeout(() => {
          this.initWebSocket(this.globalCallback);
          this.socketError = this.socketError + 1;
        }, 2000);
      }
    } else {
      this.isReconnect = false;
      // this.stopSocket();
      this.globalCallback("重试次数已用完");

    }
  }

  //断开连接
  stopSocket() {
    this.isReconnect = false;
    this.websocket && this.websocket.close();
    this.heartTime && clearInterval(this.heartTime);
    this.socketHeart = 0;
    this.socketError = 0;
  }

  // 登录 & 心跳发送数据结构
  sendHeartData(sub, body) {
    let _data = {
      cmd: "user",
      sub: sub,
      seq: this.seq++,
      type: 0,
      time: new Date().getTime(),
      body: JSON.stringify(body),
    };
    this.sendMessage(JSON.stringify(_data));
  }
  //发送数据结构
  sendData(cmd, sub, toId, msgType, msg) {
    let _data = {
      cmd: cmd,
      sub: sub,
      seq: this.seq++,
      type: 0,
    };
    let _body = {
      cmsgId: new Date().getTime(),
      fromId: this.userId,
      toId: toId,
      msgType: msgType,
      msg: msg,
      time: new Date().getTime(),
    };

    _data.body = _body;
    // console.log("传值");
    // console.log(_data);

    _data.body = AesEncode(JSON.stringify(_body), this.aeskey);

    this.sendMessage(JSON.stringify(_data));
  }
}

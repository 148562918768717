import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import "./assets/css/common.css"; 
import "./assets/css/bus.less"; 
import md5 from 'js-md5';
import VueAMap from "vue-amap";  

Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.prototype.$md5 = md5; 
Vue.use(ElementUI);
Vue.use(VueAMap);



VueAMap.initAMapApiLoader({
 key: "6f3d6a1e69960f05e15d126221894d1e",
 plugin: [
   "AMap.Autocomplete",
   "AMap.PlaceSearch",
   "AMap.Scale",
   "AMap.OverView",
   "AMap.ToolBar",
   "AMap.MapType",
   "AMap.PolyEditor",
   "AMap.CircleEditor",
   "AMap.Geocoder"
 ],
 // 默认高德 sdk 版本为 1.4.4
 v: "1.4.4"
});



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
